import styles from './styles.module.scss';

import { deleteComment } from '../../actions';

import { useModal } from '../../hooks/useModal';
import { useParams } from 'react-router-dom';

import { Button } from '../Button/Button';
import { Modal } from '../Modal/Modal';
import { Column } from '../Column/Column';

export const Comment = ({ id, name, text }) => {
  const deleteCommentModal = useModal(false);
  const { taskId } = useParams();

  if (!Boolean(name) || !Boolean(text)) return;

  return (
    <>
      <div className={styles.comment}>
        <p className={styles.name}>{name}</p>

        <Button
          className={styles.close}
          iconClass={styles.icon}
          onClick={deleteCommentModal.showModal}
          icon="close"
          size="skinny"
          color="transparent"
        />

        <p className={styles.text}>{text}</p>
      </div>

      {Boolean(deleteCommentModal.isOpenModal) && (
        <Modal className={styles.confirmModal}>
          <Column
            innerClassName={styles.columnInner}
            title="Удалить комментарий?"
          >
            <Button
              onClick={() => deleteComment(id, taskId, deleteCommentModal)}
              title="Да"
              color="white"
            />
            <Button
              onClick={deleteCommentModal.closeModal}
              title="Нет"
              color="white"
            />
          </Column>
        </Modal>
      )}
    </>
  );
};

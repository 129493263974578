import styles from './styles.module.scss';

import classNames from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';
import { getTaskById, getColumns, getColumnsOrder } from '../../actions';

import { useDragDrop } from '../../hooks/useDragDrop';
import { useModal } from '../../hooks/useModal';
import { useParams } from 'react-router-dom';

import { Column } from '../../components/Column/Column';
import { Checkbox } from '../../components/Checkbox/Checkbox';
import { TaskList } from '../../components/TaskList/TaskList';
import { Modal } from '../../components/Modal/Modal';
import { TaskForm } from '../../components/TaskForm/TaskForm';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

import MODE from '../../constants/mode';

const mainClass = classNames(styles.container, styles.main);

export const HomePage = ({ mode }) => {
  const initialColumns = getColumns();
  const columnsOrder = getColumnsOrder();

  const { columns, onDragEnd } = useDragDrop(initialColumns);

  const isCreateMode = mode === MODE.create;
  const isEditMode = mode === MODE.edit;
  const createTaskModal = useModal(isCreateMode);
  const editTaskModal = useModal(isEditMode);

  const { taskId } = useParams();
  const task = getTaskById(taskId);

  if (isEditMode && !Boolean(task)) {
    return <NotFoundPage />;
  }

  return (
    <>
      <header className={styles.container}>
        <nav className={styles.nav}>
          <Checkbox title="Комментарий" />
          <Checkbox title="Описание" />
          <Checkbox title="Тег" />
        </nav>
      </header>

      {Boolean(columnsOrder.length) && (
        <main className={mainClass}>
          <DragDropContext onDragEnd={onDragEnd}>
            {columnsOrder.map((id) => {
              const { id: columnId, title: columnTitle, taskIds } = columns[id];

              return (
                <Column title={columnTitle} key={columnId}>
                  <TaskList taskIds={taskIds} columnId={columnId} />
                </Column>
              );
            })}
          </DragDropContext>
        </main>
      )}

      {createTaskModal.isOpenModal && (
        <Modal openedModal={createTaskModal}>
          <TaskForm
            mode={MODE.create}
            openedModal={createTaskModal}
            view="modal"
          />
        </Modal>
      )}

      {editTaskModal.isOpenModal && (
        <Modal openedModal={editTaskModal}>
          <TaskForm
            taskId={taskId}
            mode={MODE.edit}
            openedModal={editTaskModal}
            view="modal"
          />
        </Modal>
      )}
    </>
  );
};

import styles from './styles.module.scss';

import classNames from 'classnames';
import { saveComment } from '../../actions';

import { useModal } from '../../hooks/useModal';
import { useParams } from 'react-router-dom';

import { Button } from '../Button/Button';
import { Column } from '../Column/Column';
import { Textarea } from '../Textarea/Textarea';
import { Modal } from '../Modal/Modal';

export const CommentModalForm = ({ className, showCommentCreateModal }) => {
  const addCommentModal = useModal(showCommentCreateModal);
  const { taskId } = useParams();

  if (!taskId) return;

  return (
    <>
      <Button
        className={className}
        onClick={addCommentModal.showModal}
        title="Добавить комментарий"
        icon="plus"
        size="skinny"
        color="transparent"
      />

      {Boolean(addCommentModal.isOpenModal) && (
        <Modal openedModal={addCommentModal}>
          <Column title="Добавить комментарий">
            <form className={classNames(styles.form, styles.modal)} action="">
              <div className={styles.fields}>
                <Textarea placeholder="Имя" text="" />

                <Textarea placeholder="Комментарий" text="" rows="9" />
              </div>

              <Button
                onClick={() => saveComment(taskId, addCommentModal)}
                title="Сохранить"
                size="wide"
              />
            </form>
          </Column>
        </Modal>
      )}
    </>
  );
};

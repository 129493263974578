import styles from './styles.module.scss';

import classNames from 'classnames';
import { getIcon } from '../Icons/Icons';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';

import { useModal } from '../../hooks/useModal';

import { TagList } from '../TagList/TagList';
import { Modal } from '../Modal/Modal';
import { TaskForm } from '../TaskForm/TaskForm';

import MODE from '../../constants/mode';

const iconDots = getIcon('dots', styles.iconDots);
const iconMessageAlert = getIcon('messageAlert', styles.iconAlert);
const iconMessageText = getIcon('messageText', styles.iconText);

export const Task = ({ task, dragIndex }) => {
  const editTaskModal = useModal(false);

  if (!task) return;

  const { id: taskId, title, description, tags, commentIds } = task;
  const hasAlert = Boolean(commentIds.length) || Boolean(description);

  const handleOnTaskClick = (event) => {
    if (event.target.closest('.js-editTaskButton')) return;

    editTaskModal.showModal();
  };

  return (
    <>
      <Draggable draggableId={taskId} index={dragIndex}>
        {(provided, snapshot) => (
          <div
            className={classNames(styles.task, {
              [styles.isDragging]: snapshot.isDragging,
            })}
            onClick={handleOnTaskClick}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <p className={styles.title}>{title}</p>

            <Link
              to={`full/${taskId}`}
              className={classNames('js-editTaskButton', styles.editLink)}
            >
              {iconDots}
            </Link>

            <TagList className={styles.tagList} tags={tags} />

            {hasAlert && (
              <div className={styles.alerts}>
                {Boolean(commentIds.length) && iconMessageAlert}

                {Boolean(description) && iconMessageText}
              </div>
            )}
          </div>
        )}
      </Draggable>

      {editTaskModal.isOpenModal && (
        <Modal openedModal={editTaskModal}>
          <TaskForm
            taskId={taskId}
            mode={MODE.edit}
            openedModal={editTaskModal}
            view="modal"
          />
        </Modal>
      )}
    </>
  );
};

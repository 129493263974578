import styles from './styles.module.scss';

import { getCommentsbyIds } from '../../actions';

import { useMemo } from 'react';

import { Comment } from '../Comment/Comment';

export const CommentList = ({ commentIds }) => {
  const comments = useMemo(() => {
    return getCommentsbyIds(commentIds);
  }, [commentIds]);

  if (!Boolean(comments?.length)) return;

  return (
    <ul className={styles.list}>
      {comments.map(({ id, name, text }) => (
        <li className={styles.item} key={id}>
          <Comment id={id} name={name} text={text} />
        </li>
      ))}
    </ul>
  );
};

import styles from './styles.module.scss';

import classNames from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import { getTasksByIds } from '../../actions';

import { useMemo } from 'react';
import { useModal } from '../../hooks/useModal';

import { Button } from '../Button/Button';
import { Task } from '../Task/Task';
import { Modal } from '../../components/Modal/Modal';
import { TaskForm } from '../../components/TaskForm/TaskForm';

import MODE from '../../constants/mode';

export const TaskList = ({ taskIds, columnId }) => {
  const createTaskModal = useModal(false);

  const tasks = useMemo(() => {
    return getTasksByIds(taskIds);
  }, [taskIds]);

  return (
    <>
      <Droppable droppableId={columnId}>
        {(provided, snapshot) => (
          <ul
            className={classNames(styles.list, {
              [styles.isDraggingOver]: snapshot.isDraggingOver,
            })}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {Boolean(tasks.length) ? (
              tasks.map((task, dragIndex) => {
                return (
                  <li className={styles.task} key={task.id}>
                    <Task task={task} dragIndex={dragIndex} />
                  </li>
                );
              })
            ) : (
              <li
                className={classNames(styles.notFound, {
                  [styles.isDraggingOver]: snapshot.isDraggingOver,
                })}
              >
                Здесь еще ничего нет :(
              </li>
            )}

            {provided.placeholder}
          </ul>
        )}
      </Droppable>

      {columnId !== 'finished' && (
        <>
          <Button
            onClick={createTaskModal.showModal}
            title="Добавить задачу"
            icon="plus"
            size="wide"
          />
        </>
      )}

      {createTaskModal.isOpenModal && (
        <Modal openedModal={createTaskModal}>
          <TaskForm
            mode={MODE.create}
            openedModal={createTaskModal}
            view="modal"
          />
        </Modal>
      )}
    </>
  );
};

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { UIPage } from './pages/UIPage/UIPage';
import { HomePage } from './pages/HomePage/HomePage';
import { TaskPage } from './pages/TaskPage/TaskPage';
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage';

import MODE from './constants/mode';

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<HomePage />} />
        <Route path="create" element={<HomePage mode={MODE.create} />} />
        <Route path="edit/:taskId" element={<HomePage mode={MODE.edit} />} />
        <Route path="full/:taskId" element={<TaskPage />} />
        <Route path="full/:taskId/comment/create" element={<TaskPage showCommentCreateModal={true}/>} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

import styles from './styles.module.scss';

import classNames from 'classnames';
import { getTaskById, saveTask } from '../../actions';

import { useState } from 'react';

import { Button } from '../Button/Button';
import { Column } from '../Column/Column';
import { Textarea } from '../Textarea/Textarea';
import { TagList } from '../TagList/TagList';
import { MultiselectTag } from '../MultiselectTag/MultiselectTag';
import { CommentList } from '../CommentList/CommentList';

import MODE from '../../constants/mode';
import { CommentModalForm } from '../CommentModalForm/CommentModalForm';

export const TaskForm = ({
  taskId,
  mode,
  openedModal,
  view = 'page',
  showCommentCreateModal,
}) => {
  const [isEditMode, setIsEditMode] = useState(mode === MODE.edit);

  const isCreateMode = mode === MODE.create;
  const isNotSetMode = !isCreateMode && !isEditMode;

  const task = getTaskById(taskId);

  const formTitle = getFormTitle(mode);
  const formClass = classNames(styles.form, styles[view]);

  const saveTaskClick = () => {
    saveTask(taskId, openedModal);
    setIsEditMode(false);
  };

  return (
    <Column
      title={formTitle}
      hasMenu={isNotSetMode}
      taskId={taskId}
      setIsEditMode={setIsEditMode}
    >
      <form className={formClass} action="">
        <div className={styles.fields}>
          <Textarea
            placeholder="Название"
            text={isCreateMode ? '' : task.title}
            disabled={isNotSetMode}
          />

          <Textarea
            placeholder="Описание"
            text={isCreateMode ? '' : task.description}
            rows="9"
            disabled={isNotSetMode}
          />
        </div>

        {isEditMode && (
          <TagList
            tags={task.tags}
            isEditMode={isEditMode}
            isPageView={view === 'page'}
          />
        )}

        {isCreateMode && <MultiselectTag />}

        {isEditMode && <MultiselectTag tags={task.tags} />}

        {isNotSetMode && <CommentList commentIds={task.commentIds} />}

        {isNotSetMode && (
          <CommentModalForm
            className={styles.addCommentButton}
            showCommentCreateModal={showCommentCreateModal}
          />
        )}

        {(isCreateMode || isEditMode) && (
          <Button onClick={saveTaskClick} title="Сохранить" size="wide" />
        )}
      </form>
    </Column>
  );
};

function getFormTitle(mode) {
  switch (mode) {
    case MODE.create:
      return 'Создание задачи';
    case MODE.edit:
      return 'Редактирование задачи';
    default:
      return 'Просмотр задачи';
  }
}

import styles from './styles.module.scss';

import classNames from 'classnames';
import { getTaskById } from '../../actions';

import { Link, useParams } from 'react-router-dom';

import { Button } from '../../components/Button/Button';
import { TaskForm } from '../../components/TaskForm/TaskForm';
import { NotFoundPage } from '../NotFoundPage/NotFoundPage';

const mainClass = classNames(styles.container, styles.main);

export const TaskPage = ({ showCommentCreateModal }) => {
  const { taskId } = useParams();

  const task = getTaskById(taskId);

  if (!Boolean(task)) {
    return <NotFoundPage />;
  }

  return (
    <>
      <header className={styles.container}>
        <nav className={styles.nav}>
          <Link to="/">
            <Button
              title="Вернуться к задачам"
              icon="back"
              size="skinny"
              color="transparent"
            />
          </Link>
        </nav>
      </header>

      <main className={mainClass}>
        <TaskForm
          taskId={taskId}
          showCommentCreateModal={showCommentCreateModal}
          view="page"
        />
      </main>
    </>
  );
};

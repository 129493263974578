import styles from './styles.module.scss';

import { Link } from 'react-router-dom';

export const NotFoundPage = () => {
  return (
    <>
      <main className={styles.main}>
        <h1>404</h1>
        <h2>Мы честно искали, но ничего не нашли :(</h2>
        <p>
          Попробуйте начать с <Link to="/">главной</Link>.
        </p>
      </main>
    </>
  );
};
